import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

const BlogContext = React.createContext(null)

const BlogProvider = ({ children }) => {
  const allBlogs = useStaticQuery(query).allSanityBlog.nodes

  const [$sidebarOpened, set$sidebarOpened] = React.useState(false)
  const [$selectedCategory, set$selectedCategory] = React.useState(false)

  const value = {
    allBlogs: allBlogs.filter(({ seo }) => !!seo),
    $sidebarOpened, set$sidebarOpened,
    $selectedCategory, set$selectedCategory,
  }

  return <BlogContext.Provider value={value}>
    {children}
  </BlogContext.Provider>
}

export {
  BlogContext,
  BlogProvider,
}

const query = graphql`query BlogStore {
  allSanityBlog(sort: {fields: publishDate, order: DESC}) {
    nodes {
      title
      category {
        title
        seo { slug { current } }
      }
      body { children { text } }
      publishDate
      formattedPublishDate: publishDate(formatString: "MMMM D, y")
      thumbnail: featuredImage {
        asset { gatsbyImageData(placeholder: BLURRED, aspectRatio: 1.88) }
      }
      featuredImage {
        asset { gatsbyImageData(placeholder: BLURRED) }
      }
      seo {
        description
        slug { current }
      }
    }
  }
}`
